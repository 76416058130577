import * as React from "react"
import Seo from "../components/seo"
import { Table, Spinner } from '@themesberg/react-bootstrap';

const { useState } = React;

const IndexPage = ({ items, onItem, isLoading, cols, renderItem }) => {


    return isLoading ? <div className="text-center p-2"><Spinner animation="border" variant="primary" /></div> : <div style={{ width: '100%' }}><Table>
        <thead className="thead-light" >
            <tr>
                {
                    cols?.map(e => <th className="border-0 align-middle"> {e.label} </th>)
                }
            </tr>
        </thead >
        <tbody>
            {
                items?.map((item, key) => <tr key={key}>

                    {
                        cols?.map(e => <td className="border-0  align-middle">
                            {
                                e.onRender ? e.onRender(item) : item[e.key]
                            }
                        </td>)
                    }
                </tr>)
            }
        </tbody>
    </Table ></div>
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
