import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import useAuth from "../hooks/userAuth";
import Parse from '../vendor/Parse'
import Table from '../components/NumberTable'
import ReactJson from 'react-json-view'
import { Modal } from '@themesberg/react-bootstrap';
import ItemTable from '../components/ItemTable'
import { setIn } from "immutable";
import DraggableDiv from "../components/DraggableDiv";
const { useEffect, useState } = React;

const IndexPage = () => {
    const { isLogin } = useAuth()
    const [users, setUsers] = useState([])
    const [info, setInfo] = useState(null)
    const [cols, setCols] = useState([])
    const [filter, setFilters] = useState([])
    const run = async () => {
        const res = await Parse.Cloud.run('getItemsMeta')
        setUsers(res)
    }

    useEffect(() => {
        const _itemCol = convertToCol(users[0] || {})
        const _addCol = [
            {
                key: 'data',
                label: 'data',
                onRender: i => <>
                    {
                        <Button onClick={async () => {

                            const res = await Parse.Cloud.run('getItemData', {
                                id: i.objectId,
                                key: i.type
                            })
                            setInfo({
                                obj: i,
                                key: 'data',
                                val: res
                            })
                        }}> View</Button >
                    }
                </>
            },
            {
                key: 'delete',
                label: 'delete',
                onRender: i => <>
                    {
                        <Button onClick={async () => {

                            const res = await Parse.Cloud.run('deleteItems', {
                                ids: [i.objectId],

                            })
                            run()
                        }}> Delete</Button >
                    }
                </>
            }
        ]
        setCols([
            ..._itemCol,
            ..._addCol
        ])
    }, [users])
    const handleClose = () => {
        setInfo(null)
    }
    useEffect(() => {
        run()
    }, [])
    const renderValue = (key, obj) => {
        const val = obj[key]
        if (typeof val === 'string') {
            return val
        }
        if (val instanceof Object && val.constructor === Object) {
            return <Button onClick={() => setInfo({
                key,
                obj,
                val: val
            })}> View</Button >
        }
    }

    const convertToCol = (obj) => {
        return Object.keys(obj).map(e => {
            return {
                key: e,
                label: e,
                onRender: i => <>
                    {
                        renderValue(e, i)
                    }
                </>
            }
        })
    }

    const filters = users.reduce((prev, next, i, arr) => {
        prev[next.type] = true
        return prev
    }, {})


    const handleEdit = async (info, newValue) => {

        const res = await Parse.Cloud.run('updateItem', {
            objectId: info?.obj?.objectId,
            model: {
                [info.key]: newValue?.updated_src
            }
        })
    }
    return <Layout afterSearch={<></>} >
        <div>
            {
                Object.keys(filters)?.map(e => <Button> {e} </Button>)
            }
        </div>

        <DraggableDiv>
            <ItemTable
                cols={cols}
                data={users}
            />
        </DraggableDiv>
        <Modal fullscreen={true} as={Modal.Dialog} centered show={info} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">Information</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
                {
                    JSON.stringify(info?.val)
                }
                <ReactJson onDelete={(e) => { handleEdit(info, e) }} onAdd={(e) => handleEdit(info, e)} src={info?.val} onEdit={(e) => { handleEdit(info, e) }} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </Layout >
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
